'use client';

import { useEffect } from 'react';

import Link from 'next/link';

import Image from '@wander/ui/src/components/Image';

import img from './error.png';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  useEffect(() => {
    // Log the error to an error reporting service 2
    console.error(error);
  }, [error]);

  return (
    <div className='grid h-screen'>
      <div className='isolate grid grid-cols-1 sm:grid-cols-2'>
        <div className='relative col-[1] row-[1]'>
          <Image
            src={img as any as string}
            alt='wander error page'
            width={810}
            height={1176}
            className='absolute right-0 top-0 -z-10 ml-auto h-full w-auto object-contain'
          />
        </div>
        <div className='col-[1] row-[1] grid items-center justify-start pl-8 sm:col-[2]'>
          <div>
            <p className='mb-2 text-5xl'>Oops :/</p>
            <h1 className='mb-4'>Something went wrong !</h1>
            <div className='flex gap-1'>
              <button
                className='underline'
                onClick={
                  // Attempt to recover by trying to re-render the segment
                  () => reset()
                }
              >
                Try again
              </button>
              <span>or go</span>
              <Link href='/' className='underline'>
                back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
